<template>
  <div v-if="loading" class="text-center" style="height: 500px;">
    <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
  </div>
  <div v-else style="text-align: left;">
    <div class="grey lighten-5 section">
      <v-form :disabled="!isEditAccess" @submit.prevent="save">
        <v-text-field
          label="Style No"
          v-model="model.styleNo"
          required
          disabled
          outlined
          dense
          color="purple darken-3"
        ></v-text-field>
        <v-text-field
          label="Style Name"
          v-model="model.styleName"
          disabled
          outlined
          dense
          color="purple darken-3"
        ></v-text-field>
        <v-text-field
          label="Buyer / Business Name"
          v-model="model.brandName"
          required
          disabled
          outlined
          dense
          color="purple darken-3"
        ></v-text-field>
        <v-text-field
          label="Customer First Name"
          v-model="model.customerFirstName"
          outlined
          dense
          disabled
          color="purple darken-3"
        ></v-text-field>
        <v-text-field
          label="Customer Last Name"
          v-model="model.customerLastName"
          disabled
          outlined
          dense
          color="purple darken-3"
        ></v-text-field>
        <v-text-field
          label="Email"
          v-model="model.email"
          disabled
          outlined
          dense
          color="purple darken-3"
        ></v-text-field>

        <div class="country-code">
          <v-container style="padding: 0px;">
            <v-row no-gutters>
              <v-col cols="4" md="1" sm="2" :align="'left'">
                <vue-country-code
                  :disabled="!isEditAccess"
                  style="height: 40px;"
                  @onSelect="onSelect"
                  :defaultCountry="country"
                >
                </vue-country-code>
              </v-col>

              <v-col cols="8" md="11" sm="10" :align="'left'">
                <div class="phone-number">
                  <v-text-field
                    v-if="!editPhone"
                    @click="editPhone=true"
                    v-model="model.phone"
                    data-cy="phone-number-field"
                    outlined
                    dense
                    color="purple darken-3"
                  ></v-text-field>
                </div>

                <div class="phone-number">
                  <v-text-field
                    v-if="editPhone"
                    v-model="model.number"
                    :error-messages="numberValidationError"
                    :prefix="model.numberCode"
                    v-on:keypress="PhoneInputNumbersOnly"
                    color="blue darken-4"
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>

        <md-field
          id="category"
          :class="getValidationClass('category')"

        >
          <label for="category">Category</label>
          <md-select
            name="category"
            v-model="model.category"
          >
            <md-option
              class="list_color"
              v-for="category in categories"
              :key="'category-' + category.id"
              :value="category.id"
              >{{ category.name }}</md-option
            >
          </md-select>
          <span
            class="md-error error_msg_color"
            v-if="categoryValidationError"
            >{{ categoryValidationError }}</span
          >
        </md-field>
        <v-text-field
          type="number"
          min="0"
          prefix="$"
          v-on:keypress="InputNumbersOnly"
          v-model="model.targetSalesPrice"
          :error-messages="targetSalesPriceErrors"
          outlined
          dense
          hint="The price you hope to sell your product"
          color="purple darken-3"
        >
          <template slot="label">
            <span>Target Sales Price</span>
            <span class="required-field">*</span>
          </template>
        </v-text-field>
        <v-text-field
          type="number"
          min="0"
          prefix="$"
          v-on:keypress="InputNumbersOnly"
          v-model="model.targetMakeCost"
          :error-messages="targetMakeCostErrors"
          outlined
          dense
          hint="The price you hope to make the product"
          color="purple darken-3"
        >
          <template slot="label">
            <span>Target Make Cost</span>
            <span class="required-field">*</span>
          </template>
        </v-text-field
        >
        <span v-if="isInvalidPrice" class="invalid-input">
          Target Make Cost should be less than Target Sales Price
        </span>
        <div class="mb-4 mt-4">
          <span>Size Category</span>
          <span class="required-field">*</span>
        </div>
        <v-radio-group
          v-model="model.selectedSizeCategory"
          :error-messages="sizeCategoryErrors"
          @input="$v.model.selectedSizeCategory.$touch()"
          @blur="$v.model.selectedSizeCategory.$touch()"
        >
          <v-radio
            v-for="(category, index) in sizeCategory"
            :key="index"
            :label="category.category"
            :value="category.id"
            color="purple darken-3"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          v-if="model.selectedSizeCategory === 4"
          label="Other"
          style="width: 300px;"
          v-model="model.otherCategory"
          :error-messages="otherCategoryErrors"
          maxlength="10"
          required
          @input="$v.model.otherCategory.$touch()"
          @blur="$v.model.otherCategory.$touch()"
          outlined
          dense
          color="purple darken-3"
        >
          <template v-if="!model.otherCategory" v-slot:append-outer>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon class="ml-1" v-on="on" :style="model.otherCategory ? 'color:gray;' : 'color:red;'">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              Please add other category type manually.
            </v-tooltip>
          </template>
        </v-text-field>
        <div v-if="model.selectedSizeCategory !== null" class="mb-4">
          {{
            model.selectedSizeCategory === 1
              ? "Women's Geographical Size"
              : model.selectedSizeCategory === 2
                ? "Men's Geographical Size"
                : model.selectedSizeCategory === 3
                  ? "Kid's Geographical Size"
                  : "Sizes"
          }}
          <span class="required-field">*</span>
          <span v-if="model.selectedSizeCategory===4">
            <template>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-1" v-on="on" :style="model.otherCategory ? 'color:orange;' : 'color:red;'">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                  <span v-if="model.otherCategory">
                    If selected other category please add the sizes manually.
                  </span>
                  <span v-else>
                    This will appear after filling the other category.
                  </span>
              </v-tooltip>
            </template>
          </span>
        </div>
        <v-radio-group
          v-if="
            model.selectedSizeCategory === 1 || model.selectedSizeCategory === 2
          "
          v-model="model.sizeChart"
          :error-messages="sizeChartErrors"
          @input="$v.model.sizeChart.$touch()"
          @blur="$v.model.sizeChart.$touch()"
        >
          <v-radio
            v-for="(chart, index) in model.selectedSizeCategory === 1
              ? mensSizeChart
              : model.selectedSizeCategory === 2
              ? womensSizeChart
              : null"
            :key="index"
            :label="chart.category"
            :value="chart.id"
            color="purple darken-3"
          ></v-radio>
        </v-radio-group>
        <div
          v-if="model.selectedSizeCategory === 4 && model.otherCategory !== ''"
        >
          <div
            v-for="(size, index) in model.otherSizes"
            :key="index"
            :set="(v = $v.model.otherSizes.$each[index])"
            style="display: flex;"
          >
            <v-text-field
              :label="`SIZE${index + 1}`"
              v-model="size.size"
              maxlength="10"
              @input="addOtherSizesBulk(size.size, index)"
              :error-messages="v.size.$error ? ['Size is required'] : null"
              required
              outlined
              dense
              color="purple darken-3"
            ></v-text-field>
            <div class="tooltip ml-2">
            <v-btn
              class="mr-4"
              @click="removeSize(index)"
              fab
              dark
              x-small
              color="error"
            >
              <v-icon dark>
                mdi-minus
              </v-icon>
            </v-btn
            >
            <span class="tooltiptext">remove size</span>
          </div>
          </div>
          <div class="tooltip mb-5">
            <v-btn
              class="mr-4 mb-4"
              @click="addSize"
              fab
              dark
              x-small
              color="purple darken-3"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn
            >
            <span class="tooltiptext">Add Size</span>
          </div>
        </div>
        <v-radio-group
          v-if="model.selectedSizeCategory === 3"
          v-model="model.kidsCategory"
          :error-messages="kidsCategoryErrors"
          @input="$v.model.kidsCategory.$touch()"
          @blur="$v.model.kidsCategory.$touch()"
        >
          <v-radio
            v-for="(chart, index) in kidsSizeChart"
            :key="index"
            :label="chart.category"
            :value="chart.id"
            color="purple darken-3"
          ></v-radio>
        </v-radio-group>
        <div
          v-if="model.kidsCategory !== null && model.selectedSizeCategory === 3"
        >
          <div v-for="(chart, index) in kidsSizeChart" :key="index">
            {{
              model.kidsCategory === chart.id ? `Kids ${chart.category}` : ""
            }}
          </div>
        </div>
        <v-radio-group
          v-if="model.selectedSizeCategory === 3 && model.kidsCategory !== null"
          v-model="model.sizeChart"
          :error-messages="sizeChartErrors"
          @input="$v.model.sizeChart.$touch()"
          @blur="$v.model.sizeChart.$touch()"
        >
          <v-radio
            v-for="(chart, index) in model.selectedSizeCategory === 3 &&
            model.kidsCategory === 1
              ? kidsUkSizeCategory
              : model.selectedSizeCategory === 3 && model.kidsCategory === 2
              ? kidsUsSizeCategory
              : null"
            :key="index"
            :label="chart.category"
            :value="chart.id"
            color="purple darken-3"
          ></v-radio>
        </v-radio-group>
        <div
          v-if="model.selectedSizeCategory !== null && model.sizeChart !== null"
          style="display: flex; flex-direction: row;"
          class="mb-4"
        >
          <div class="pr-1">
            {{
              model.selectedSizeCategory === 1
                ? "Women's"
                : model.selectedSizeCategory === 2
                  ? "Men's"
                  : model.selectedSizeCategory === 3 &&
                  model.kidsCategory !== null
                    ? ""
                    : ""
            }}
          </div>
          <div
            v-for="(sizeChartItem, index) in model.selectedSizeCategory === 1
              ? womensSizeChart
              : model.selectedSizeCategory === 2
              ? mensSizeChart
              : model.selectedSizeCategory === 3 && model.kidsCategory == 1
              ? kidsUkSizeCategory
              : model.selectedSizeCategory === 3 && model.kidsCategory == 2
              ? kidsUsSizeCategory
              : null"
            :key="index"
          >
            {{
              sizeChartItem.id === model.sizeChart ? sizeChartItem.category : ""
            }}
          </div>
        </div>
        <div
          v-if="model.selectedSizeCategory !== null && model.sizeChart !== null"
        >
          <div
            class="mb-4 "
            v-for="(sizeChartItem, index) in model.selectedSizeCategory === 1
              ? womensSizeChart
              : model.selectedSizeCategory === 2
              ? mensSizeChart
              : model.selectedSizeCategory === 3 && model.kidsCategory === 1
              ? kidsUkSizeCategory
              : model.selectedSizeCategory === 3 && model.kidsCategory === 2
              ? kidsUsSizeCategory
              : null"
            :key="index"
          >
            <div v-viewer>
              <div class="contain" v-if="sizeChartItem.id === model.sizeChart">
                <img
                  v-if="sizeChartItem.id === model.sizeChart"
                  :src="sizeChartItem.image"
                  style="object-fit: contain; height: 100%; width: 100%;"
                />
              </div>
            </div>
          </div>
        </div>

        <size-breakdown
          rowId="samplebulk"
          :dropdown="true"
          :showBreakdowns="showBreakdowns"
          :breakdowns="model.sampleColorSizeBreakdown"
          :sizeChart="getTheSizeChart"
          quantityTitle="Sample Quantity"
          @update-breakdowns="updateSampleBreakdowns"
        >
          <div class="mb-4 mt-10" :style="!showBreakdowns ? 'color:red;' : 'color:black;'">Color and Size Breakdown for Sampling
            <template v-if="!showBreakdowns">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-5" v-on="on" style="color:red;">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                  <span>
                    This field will appear after filling the Size Category, Geographical Size
                  </span>
              </v-tooltip>
            </template>
          </div>
        </size-breakdown>
        <div class="mb-4" :style="!showBreakdowns ? 'color:red;' : 'color:black;'">
          Color and Size Breakdown for Bulk
          <template v-if="!showBreakdowns">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon class="ml-5" v-on="on" style="color:red;">
                  mdi-help-circle-outline
                </v-icon>
              </template>
                <span>
                  This field will appear after filling the Size Category, Geographical Size
                </span>
            </v-tooltip>
          </template>
        </div>
        <div v-if="qtyNotMatchingError" style="color:red"> Style quantity and bulk size quantities are not matching</div>

        <size-breakdown
          rowId="totalbulk"
          :totalError="invalidBulk"
          :isSizeRequired="true"
          :showBreakdowns="showBreakdowns"
          quantityTitle="Total Quantity"
          :total="inquiryQty"
          :min="0"
          :max="100000000"
          :breakdowns="model.bulkColorSizeBreakdown"
          :sizeChart="getTheSizeChart"
          @update-breakdowns="updateBulkBreakdowns"
        >
        </size-breakdown>
        <div class="btn-align">
          <v-btn
            color="green"
            v-if="isEditAccess && !isOwner"
            @click="redirectToCost"
          >Save & Cost Sheet</v-btn
          >
          <v-btn color="#ff5252" v-if="isEditAccess" @click="save">
            Save
          </v-btn>

          <v-btn
            class="continue_button_color"
            @click="isEditAccess ? continueStep() : continueToNext()"
          >Continue
          </v-btn>
        </div>
        <v-dialog v-model="exceededDialog" persistent max-width="400">
          <v-card>
            <v-card-text class="text-h3">
              Style quantity changed
            </v-card-text>
            <div class="text-h5">
              Would you like to continue
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="purple darken-1" text @click="resetQuantity">
                Disagree
              </v-btn>
              <v-btn
                color="purple darken-1"
                text
                @click="
                  agreeStatus();
                "
              >
                Agree
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="subceededDialog" persistent max-width="400">
        <v-card>
          <v-card-text class="text-h3">
            Style quantity changed
          </v-card-text>
          <div class="text-h5">
            Would you like to continue
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="purple darken-1" text @click="resetQuantity">
              Disagree
            </v-btn>
            <v-btn
              color="purple darken-1"
              text
              @click="
                agreeStatus();
              "
            >
              Agree
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-form>
    </div>
    <v-dialog
      max-width="500"
      v-model="commentDialog"
    >
      <Comment
        @comment-submit="commentSubmit"
        @comment-close="commentDialog = false"
        :page="1"
        serviceType="tech_pack"
      />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
import * as conf from "../../../conf.yml";
import validationMixins from "../../../validation/validation_mixin";
import {validationMixin} from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
// import {required, requiredIf} from "vuelidate/lib/validators";
import VueViewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import restAdapter from "../../../restAdapter";
import notification from "../../../notification";
import countryCodes from "country-codes-list";
import Vue from "vue";
import Comment from "../Comment.vue";
import { resetLeaveAlert, setLeaveAlert } from '../../../utils/confirmBox';

import VueCountryCode from "vue-country-code";
import codes from "country-codes-list";
import SizeBreakdown from '../../../components/SizeBreakdown.vue';

Vue.use(VueCountryCode);

Vue.use(VueViewer);

const validateQuantity = (value) => {
  if (!value) {
    return false;
  }
  value = parseInt(value);
  if (value >= 50 && value <= 100000000) {
    return true;
  }
  return false;
};

export default {
  name: "InquiryAndProductionDetails",
  mixins: [validationMixin,validationMixins],
  components: {SizeBreakdown, Comment},

  data() {
    return {
      redirectToCostSheet: false,
      commentDialog: false,
      isSubmit: false,
      isContinue: false,
      invalidBulk: false,
      qtyNotMatchingError:false,
      sampleQtySaveError: false,
      country: "",
      saveError: false,
      baseUrl: "",
      inquiryId: "",
      inquiryQty: null,
      categories: [],
      loading: false,
      exceededDialog: false,
      subceededDialog: false,
      countryCodes: [],
      arr: [],
      sampleSelect: [],
      editPhone: false,
      updateMessage: "",
      sizeCategory: [
        {id: 1, category: "Women"},
        {id: 2, category: "Men"},
        {id: 3, category: "Kids"},
        {id: 4, category: "Other"},
      ],
      mensSizeChart: [
        {
          id: 1,
          category: "UK Size Chart",
          sizes: ["UK 36", "UK 38", "UK 40", "UK 42", "UK 44", "UK 46"],
          image: require(`../../../assets/size-charts/MEN_S_UK_SIZE_CHART.png`),
        },
        {
          id: 2,
          category: "Europe Size Chart",
          sizes: ["EU 46", "EU 48", "EU 50", "EU 52", "EU 54", "EU 56", "EU 58", "EU 60"],
          image: require(`../../../assets/size-charts/MEN_S_EUROPEAN_SIZE_CHART.png`),
        },
        {
          id: 3,
          category: "US Size Chart",
          sizes: ["US 34", "US 36", "US 38", "US 40", "US 42", "US 44", "US 46", "US 48"],
          image: require(`../../../assets/size-charts/MEN_S_AU___US_SIZE_CHART.png`),
        },
        {
          id: 4,
          category: "AUS Size Chart",
          sizes: ["AUS 34", "AUS 36", "AUS 38", "AUS 40", "AUS 42", "AUS 44", "AUS 46", "AUS 48"],
          image: require(`../../../assets/size-charts/MEN_S_AU___US_SIZE_CHART.png`),
        },
      ],
      womensSizeChart: [
        {
          id: 1,
          category: "UK Size Chart",
          sizes: ["UK 8", "UK 10", "UK 12", "UK 14", "UK 16", "UK 18"],
          image: require(`../../../assets/size-charts/WOMEN_S_UK_SIZE_CHART.png`),
        },
        {
          id: 2,
          category: "Europe Size Chart",
          sizes: ["EU 34", "EU 36", "EU 38", "EU 40", "EU 42", "EU 44", "EU 46", "EU 48", "EU 50", "EU 52", "EU 54"],
          image: require(`../../../assets/size-charts/WOMEN_S_EUROPEAN_SIZE_CHART.png`),
        },
        {
          id: 3,
          category: "US Size Chart",
          sizes: ["US 2", "US 4", "US 6", "US 8", "US 10", "US 12", "US 14"],
          image: require(`../../../assets/size-charts/WOMEN_S_AU___US_SIZE_CHART.png`),
        },
        {
          id: 4,
          category: "AUS Size Chart",
          sizes: ["AUS 4", "AUS 6", "AUS 8", "AUS 10", "AUS 12", "AUS 14", "AUS 16", "AUS 18"],
          image: require(`../../../assets/size-charts/WOMEN_S_AU___US_SIZE_CHART.png`),
        },
      ],
      kidsSizeChart: [
        {id: 1, category: "UK Size Chart"},
        {id: 2, category: "US Size Chart"},
      ],
      kidsUkSizeCategory: [
        {
          id: 1,
          category: "Infants ( New born - 24 M )",
          sizes: ["New Born", "UK 3M", "UK 6M", "UK 9M", "UK 12M", "UK 18M", "UK 24M"],
          image: require(`../../../assets/size-charts/INFANT_UK_NEWBORN_24_M.png`),
        },
        {
          id: 2,
          category: "Toddler 3T - 24 T",
          sizes: ["UK 3T", "UK 4T"],
          image: require(`../../../assets/size-charts/TODDLER_UK_3T_4T.png`),
        },
        {
          id: 3,
          category: "Boy/Girl 5yrs - 7 yrs",
          sizes: ["UK 5", "UK 6", "UK 7"],
          image: require(`../../../assets/size-charts/BOY___GIRL_UK_5_YRS_7_YRS.png`),
        },
        {
          id: 4,
          category: "Girl 8 yrs - 16 yrs",
          sizes: ["UK 8", "UK 10", "UK 12", "UK 14", "UK 16"],
          image: require(`../../../assets/size-charts/GIRL_UK_8_YRS_16_YRS.png`),
        },
        {
          id: 5,
          category: "Boy 8 yrs - 16 yrs",
          sizes: ["UK 8", "UK 10", "UK 12", "UK 14", "UK 16"],
          image: require(`../../../assets/size-charts/BOYS_UK_8YRS_16_YRS.png`),
        },
      ],
      kidsUsSizeCategory: [
        {
          id: 1,
          category: "Infants ( New born - 24 M )",
          sizes: [
            "PREEMIREE",
            "NEW BORN",
            "US 3M",
            "US 6M",
            "US 9M",
            "US 12M",
            "US 18M",
            "US 24M",
          ],
          image: require(`../../../assets/size-charts/INFANT_US_PREMIREE_24_M.png`),
        },
        {
          id: 2,
          category: "Boy/Girl 2 yrs - 6x yrs",
          sizes: ["US 2", "US 3", "US 4", "US 5", "US 6", "US 6X"],
          image: require(`../../../assets/size-charts/BOY___GIRL_US_2YRS_6X_YRS.png`),
        },
        {
          id: 3,
          category: "Girl 7 yrs - 20 yrs",
          sizes: ["US 7", "US 8", "US 10", "US 12", "US 14", "US 16", "US 18", "US 20"],
          image: require(`../../../assets/size-charts/GIRL_US_7_YRS_20_YRS.png`),
        },
        {
          id: 4,
          category: "Boy 7 yrs - 20 yrs",
          sizes: ["US 7", "US 8", "US 10", "US 12", "US 14", "US 16", "US 18", "US 20"],
          image: require(`../../../assets/size-charts/BOYS_US_7_YRS_20_YRS.png`),
        },
      ],

      model: {
        styleName: "",
        customerFirstName: "",
        sampleQunatity: null,
        customerLastName: "",
        styleNo: "",
        category: null,
        brandName: "",
        email: "",
        phone: "",
        number: null,
        numberCode: null,
        targetSalesPrice: "",
        targetMakeCost: "",
        totalQuantity: "",
        bulkColorSizeBreakdown: [],
        sampleColorSizeBreakdown: [],
        selectedSizeCategory: null,
        otherCategory: "",
        sizeChart: null,
        kidsCategory: null,
        otherSizes: [
          {
            size: "",
          },
        ]
      },
      ignoreFormEdit: false
    };
  },
  validations: {
    model: {
      targetSalesPrice: {required},
      targetMakeCost: {required},
      selectedSizeCategory: {required},
      otherSizes: {
        $each: {
          size: {
            required: requiredIf(function () {
              if (
                this.model.sizeChart === null &&
                this.model.selectedSizeCategory === 4
              ) {
                return true;
              } else {
                return false;
              }
            }),
          },
        },
      },
      sizeChart: {
        required: requiredIf(function () {
          if (
            this.model.sizeChart === null &&
            this.model.selectedSizeCategory !== 4
          ) {
            return true;
          } else {
            return false;
          }
        }),
      },
      otherCategory: {
        required: requiredIf(function () {
          if (this.model.selectedSizeCategory === 4) {
            return true;
          } else {
            return false;
          }
        }),
      },
      kidsCategory: {
        required: requiredIf(function () {
          if (
            this.model.kidsCategory === null &&
            this.model.selectedSizeCategory === 3
          ) {
            return true;
          } else {
            return false;
          }
        }),
      },
      numberCode: {
        required,
      },
      number: {
        required,
        minLength: minLength(7),
        maxLength: maxLength(16),
      },
      category:{required}
    },
  },
  props: {
    isOwner: {
      type: Boolean,
    },
    isEditAccess: {
      type: Boolean,
    },
  },

  watch: {
    model: {
      handler: function () {
        this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
      },
      deep: true,
    }
  },

  methods: {
    redirectToCost() {
      if (this.$store.state.routeLeaveAlert) {
        this.redirectToCostSheet = true;
        this.submitAndLeave();
      } else {
        this.$router.push({ name: "Style Costing Sheet" });
      }
    },
    updateSampleBreakdowns(breakdowns){
      this.model.sampleColorSizeBreakdown = breakdowns;
      this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map(breakdown => breakdown);
    },
    updateBulkBreakdowns(breakdowns){
      this.model.bulkColorSizeBreakdown = breakdowns;
      this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map(breakdown => breakdown);
    },
    onSelect({name, iso2, dialCode}) {
      this.model.numberCode = '+' + dialCode;
      this.editPhone = true;
    },
    sampleSelectList() {
      for (let i = 0; i < 6; i++) {
        this.sampleSelect.push(i);
      }
    },
    clearForm() {
      this.$v.model.$reset();
      this.model.sampleQunatity = null;
      this.model.selectedSizeCategory = null;
      this.model.sizeChart = null;
      this.model.kidsCategory = null;
      this.model.targetSalesPrice = null;
      this.model.targetMakeCost = null;
      this.model.totalQuantity = null;
      this.model.bulkDeliveryDate = null;
      this.model.measurementUnit = null;
      (this.model.otherCategory = ""),
        (this.model.bulkComments = ""),
        (this.model.sampleComments = ""),
        (this.model.measurementChartDetail = "");
      this.model.filesMeasurementChart.splice(
        0,
        this.model.filesMeasurementChart.length
      );
      this.model.measurementChart.splice(0, this.model.measurementChart.length);
      this.model.sizeMeasuringGuide.splice(
        0,
        this.model.sizeMeasuringGuide.length
      );

      this.model.filesSizeMeasuringGuide.splice(
        0,
        this.model.filesSizeMeasuringGuide.length
      );

      this.model.colorFiles.splice(0, this.model.colorFiles.length);

      this.model.filesColor.splice(0, this.model.filesColor.length);

      this.model.otherSizes.splice(0, this.model.otherSizes.length);

      this.model.sampleColorSizeBreakdown.splice(
        0,
        this.model.sampleColorSizeBreakdown.length
      );

      this.model.bulkColorSizeBreakdown.splice(
        0,
        this.model.bulkColorSizeBreakdown.length
      );

      this.model.otherSizes.push({
        size: "",
      });

    },
    continueToNext() {
      this.$emit("set-done");
      this.redirectToCostSheet = false;
    },
    agreeStatus(){
      this.inquiryQty = this.model.totalQuantity;
      this.subceededDialog = false;
      this.exceededDialog = false;
      this.qtyNotMatchingError = false;
      this.save();
    },
    continueStep() {
      this.isContinue = true;
      this.redirectToCostSheet = false;
      const bulkTotal = this.calculateTotalQty(this.model.bulkColorSizeBreakdown);
      this.model.totalQuantity = bulkTotal;

      this.invalidBulk = !validateQuantity(this.model.totalQuantity);

      if(this.invalidBulk){
        this.invalidBulk = true;
        document.getElementById(`totalbulk-total`).scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "center",
        });
        return;
      }

      if(!bulkTotal && bulkTotal != this.inquiryQty){
        this.qtyNotMatchingError = true;
        return;
      }

      if (this.model.totalQuantity > this.inquiryQty) {
        this.exceededDialog = true;
        return;
      }

      if (this.model.totalQuantity < this.inquiryQty) {
        this.subceededDialog = true;
        return;
      }

      this.$v.model.$touch();

      if (this.$v.model.$invalid) {
        this.$emit("error");
        return;
      }

      this.checkSampleBulkList();
      if(this.sampleQtySaveError) {
        return;
      }

      this.checkBulkList();
      if (this.saveError) {
        return;
      }
      this.save();
    },
    reset() {
      this.$emit("reset-status", false);
    },
    getInquiry(id) {
      return restAdapter.get("/api/inquiries/" + id);
    },
    getCategories() {
      return restAdapter.get("/api/inquire_categories");
    },
    getCategoryName(id) {
      const category = this.categories.find((category) => category.id === id);
      this.model.category = category.id;
      this.ignoreFormEdit = true;
    },
    getProduction() {
      return restAdapter.get(
        `/api/inquiries/${this.inquiryId}/tech_pack/production`
      );
    },
    calculateTotalQty(breakdowns){
      let totalBulkValue = 0;
      breakdowns.forEach((element) => {
        element.sizeAndQuantity.forEach((item) => {
          let totalValue = null;
          if(item.quantity){
            totalValue = parseFloat(item.quantity);
          }
          totalBulkValue = totalBulkValue + totalValue;
        });
      });
      return totalBulkValue;
    },
    calculateBulkTotalQty(){
      let totalBulkValue = 0;
      this.model.bulkColorSizeBreakdown.forEach((element) => {
        element.sizeAndQuantity.forEach((item) => {
          let totalValue = null;
          if(item.quantity){
            totalValue = parseFloat(item.quantity);
          }
          totalBulkValue = totalBulkValue + totalValue;
        });
      });
      return totalBulkValue;
    },
    calculateTotalQuantity(value_index = null) {
      this.model.totalQuantity = 0;
      this.model.bulkColorSizeBreakdown.forEach((element) => {
        element.sizeAndQuantity.forEach((item) => {
          let totalValue = null;
          if(item.quantity){
            totalValue = parseFloat(item.quantity);
          }
          this.model.totalQuantity = this.model.totalQuantity + totalValue;
        });
      });

      if (value_index === 0 || value_index) {
        this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map((item, index) => {
          if (index == value_index) {
            return {...this.model.bulkColorSizeBreakdown[index], error: false};
          }
          return {...this.model.bulkColorSizeBreakdown[index]};
        });
      }
      return;
    },
    calculateSampleQuantity() {
      this.model.sampleQunatity = 0;
      this.model.sampleColorSizeBreakdown.forEach((element) => {
        element.sizeAndQuantity.forEach((item) => {
          this.model.sampleQunatity = this.model.sampleQunatity + item.quantity;
        });
      });
    },
    resetQuantity() {
      this.exceededDialog = false;
      this.subceededDialog = false;
      this.getProduction().then((response) => {
        if (response.data.bulkBreakdowns.length !== 0) {
          this.model.bulkColorSizeBreakdown = [];
          for (
            let index = 0;
            index < response.data.bulkBreakdowns.length;
            index++
          ) {
            this.model.bulkColorSizeBreakdown.push(
              response.data.bulkBreakdowns[index]
            );
          }
        }

        if (response.data.sampleBreakdowns.length !== 0) {
          this.model.sampleColorSizeBreakdown = [];
          for (
            let index = 0;
            index < response.data.sampleBreakdowns.length;
            index++
          ) {
            this.model.sampleColorSizeBreakdown.push(
              response.data.sampleBreakdowns[index]
            );
          }
        }
      });
    },
    addOtherSizesBulk(size, index) {
      if (this.model.sampleColorSizeBreakdown.length === 0) {
        this.model.sampleColorSizeBreakdown.push({
          color: "",
          sizeAndQuantity: [],
        });
        this.model.otherSizes = [
          {
            size: "",
          },
        ];
      }

      if (this.model.bulkColorSizeBreakdown.length === 0) {
        this.model.bulkColorSizeBreakdown.push({
          color: "",
          sizeAndQuantity: [],
        });
        this.model.otherSizes = [
          {
            size: "",
          },
        ];
      }

      for (let i = 0; i < this.model.sampleColorSizeBreakdown.length; i++) {
        this.model.sampleColorSizeBreakdown[i].sizeAndQuantity[index] = {
          size: size,
          quantity: 0,
        };
      }

      for (let i = 0; i < this.model.bulkColorSizeBreakdown.length; i++) {
        this.model.bulkColorSizeBreakdown[i].sizeAndQuantity[index] = {
          size: size,
          quantity: 0,
        };
      }

      this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map(breakdown => breakdown);
      this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map(breakdown => breakdown);
    },
    addSize() {
      this.model.otherSizes.push({
        size: "",
      });

      this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map(breakdown => {
        return {...breakdown, sizeAndQuantity: this.model.otherSizes.map((size, index) => {
          if(!breakdown.sizeAndQuantity[index]){
            return {size: size.size, quantity: 0}
          }

          return {...breakdown.sizeAndQuantity[index]}
        })}
      });

      this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map(breakdown => {
        return {...breakdown, sizeAndQuantity: this.model.otherSizes.map((size, index) => {
          if(!breakdown.sizeAndQuantity[index]){
            return {size: size.size, quantity: 0}
          }

          return {...breakdown.sizeAndQuantity[index]}
        })}
      });
    },
    removeSize(index) {
      this.model.otherSizes.splice(index, 1);

      this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map(breakdown => {
        return {...breakdown, sizeAndQuantity: breakdown.sizeAndQuantity.filter((size, position) => index !== position)}
      })

      this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map(breakdown => {
        return {...breakdown, sizeAndQuantity: breakdown.sizeAndQuantity.filter((size, position) => index !== position)}
      })

      this.calculateSampleQuantity();
      this.calculateTotalQuantity();
    },
    selectedSizeCategoryWatcher(newValue, oldValue) {

      this.model.sampleColorSizeBreakdown.splice(
        0,
        this.model.sampleColorSizeBreakdown.length
      );

      this.model.bulkColorSizeBreakdown.splice(
        0,
        this.model.bulkColorSizeBreakdown.length
      );
      this.model.sizeChart = null;
      this.model.sampleQunatity = null;
      this.model.kidsCategory = null;
      this.model.totalQuantity = null;
      this.model.otherCategory = "";

      this.model.otherSizes.splice(0, this.model.otherSizes.length);

      if (newValue === 4) {
        this.model.sampleColorSizeBreakdown.push({
          color: "",
          sizeAndQuantity: [],
        });
        this.model.bulkColorSizeBreakdown.push({
          color: "",
          sizeAndQuantity: [],
        });
        this.model.otherSizes = [
          {
            size: "",
          },
        ];
      }
    },
    sizeChartWatcher() {
      this.model.sampleColorSizeBreakdown.splice(
        0,
        this.model.sampleColorSizeBreakdown.length
      );
      this.model.bulkColorSizeBreakdown.splice(
        0,
        this.model.bulkColorSizeBreakdown.length
      );
      this.model.sampleQunatity = null;
      this.model.totalQuantity = null;

      this.model.sampleColorSizeBreakdown.push({
        color: "",
        sizeAndQuantity: [],
      });
      this.model.bulkColorSizeBreakdown.push({
        color: "",
        sizeAndQuantity: [],
      });
      if (this.model.selectedSizeCategory === 1) {
        if (this.model.sizeChart === 1) {
          this.womensSizeChart[0].sizes.map((size, index) => {
            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        } else if (this.model.sizeChart === 2) {
          this.womensSizeChart[1].sizes.map((size, index) => {
            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        } else if (this.model.sizeChart === 3) {
          this.womensSizeChart[2].sizes.map((size, index) => {
            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });


            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        } else if (this.model.sizeChart === 4) {
          this.womensSizeChart[3].sizes.map((size, index) => {
            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });


            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        }
      } else if (this.model.selectedSizeCategory === 2) {
        if (this.model.sizeChart === 1) {
          this.mensSizeChart[0].sizes.map((size, index) => {

            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });

            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        } else if (this.model.sizeChart === 2) {
          this.mensSizeChart[1].sizes.map((size, index) => {

            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });

            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        } else if (this.model.sizeChart === 3) {
          this.mensSizeChart[2].sizes.map((size, index) => {

            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });


            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        } else if (this.model.sizeChart === 4) {
          this.mensSizeChart[3].sizes.map((size, index) => {

            this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });


            this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
              size: size,
              quantity: 0,
            });
          });
        }
      } else if (this.model.selectedSizeCategory === 3) {
        if (this.model.kidsCategory === 1) {
          if (this.model.sizeChart === 1) {
            this.kidsUkSizeCategory[0].sizes.map((size, index) => {

              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });


              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 2) {
            this.kidsUkSizeCategory[1].sizes.map((size, index) => {

              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 3) {
            this.kidsUkSizeCategory[2].sizes.map((size, index) => {

              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 4) {
            this.kidsUkSizeCategory[3].sizes.map((size, index) => {

              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 5) {
            this.kidsUkSizeCategory[4].sizes.map((size, index) => {

              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          }
        } else if (this.model.kidsCategory === 2) {
          if (this.model.sizeChart === 1) {
            this.kidsUsSizeCategory[0].sizes.map((size, index) => {

              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 2) {
            this.kidsUsSizeCategory[1].sizes.map((size, index) => {

              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 3) {
            this.kidsUsSizeCategory[2].sizes.map((size, index) => {

              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          } else if (this.model.sizeChart === 4) {
            this.kidsUsSizeCategory[3].sizes.map((size, index) => {

              this.model.sampleColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });

              this.model.bulkColorSizeBreakdown[0].sizeAndQuantity.push({
                size: size,
                quantity: 0,
              });
            });
          }
        }
      }
    },
    kidsCategoryWatcher() {
      this.model.sampleColorSizeBreakdown = [];
      this.model.bulkColorSizeBreakdown = [];
      this.model.sizeChart = null;
      this.model.sampleQunatity = null;
      this.model.totalQuantity = null;
    },
    InputNumbersOnly(evt) {
      /*eslint no-var: "error"*/
      /* eslint no-var: off */
      /* eslint-disable */
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkSampleBulkList(){
      if(!this.model.sampleColorSizeBreakdown.length) {
        this.sampleQtySaveError = false;
        return;
      }

      for(let i = 0; i<this.model.sampleColorSizeBreakdown.length; i++){
        let allNull = true;
        this.sampleQtySaveError = false;

        if(!this.model.sampleColorSizeBreakdown[i].color){
          this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map((item, index) => {
            if (index == i) {
              document.getElementById(`samplebulk-${index}`).scrollIntoView({
                behavior: "smooth",
                inline: "center",
                block: "center",
              });
              return {...this.model.sampleColorSizeBreakdown[index], colorError: true};
            }
            return {...this.model.sampleColorSizeBreakdown[index]};
          });
          this.sampleQtySaveError = true;
          return;
        }

        for(let j = 0; j<this.model.sampleColorSizeBreakdown[i].sizeAndQuantity.length; j++){
          if(this.model.sampleColorSizeBreakdown[i].sizeAndQuantity[j].quantity === 0 || this.model.sampleColorSizeBreakdown[i].sizeAndQuantity[j].quantity){
            allNull = false;
            this.sampleQtySaveError = false;
          }
        }
        if(allNull){
          this.model.sampleColorSizeBreakdown = this.model.sampleColorSizeBreakdown.map((item, index) =>{
            if(index == i){
              document.getElementById(`samplebulk-${index}`).scrollIntoView({
                behavior: "smooth",
                inline: "center",
                block: "center",
              });
              return {...this.model.sampleColorSizeBreakdown[index], error: true};
            }
            return {...this.model.sampleColorSizeBreakdown[index]};
          });
          this.sampleQtySaveError = true;
          return;
        }
      }
    },
    checkBulkList() {
      for (let i = 0; i < this.model.bulkColorSizeBreakdown.length; i++) {
        let allNull = true;
        this.saveError = false;

        if(!this.model.bulkColorSizeBreakdown[i].color){
          this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map((item, index) => {
            if (index == i) {
              return {...this.model.bulkColorSizeBreakdown[index], colorError: true};
            }
            return {...this.model.bulkColorSizeBreakdown[index]};
          });
          this.saveError = true;
          return;
        }

        for (let j = 0; j < this.model.bulkColorSizeBreakdown[i].sizeAndQuantity.length; j++) {
          if (this.model.bulkColorSizeBreakdown[i].sizeAndQuantity[j].quantity && parseInt(this.model.bulkColorSizeBreakdown[i].sizeAndQuantity[j].quantity)) {
            allNull = false;
            this.saveError = false;
          }
        }
        if (allNull) {
          this.model.bulkColorSizeBreakdown = this.model.bulkColorSizeBreakdown.map((item, index) => {
            if (index == i) {
              return {...this.model.bulkColorSizeBreakdown[index], error: true};
            }
            return {...this.model.bulkColorSizeBreakdown[index]};
          });
          this.saveError = true;
          return;
        }
      }
    },
    commentSubmit() {
      this.commentDialog = false;
      this.isSubmit = true;
      if (this.redirectToCostSheet) {
        this.submitAndLeave();
      } else {
        this.save();
        this.continueNextPage();
      }
    },
    continueNextPage() {
      if(this.isContinue){
        this.isContinue = false;
        this.$emit('set-done');
      }
    },
    addComment() {
      if (!this.isOwner) {
        this.commentDialog = true;
      } else {
        this.commentSubmit();
      }
    },
    verifySubmission() {
      const sampleTotal = this.calculateTotalQty(this.model.sampleColorSizeBreakdown)
      const bulkTotal = this.calculateTotalQty(this.model.bulkColorSizeBreakdown);
      this.model.totalQuantity = bulkTotal;

      this.invalidBulk = !validateQuantity(this.model.totalQuantity);
      if(this.invalidBulk){
        this.invalidBulk = true;
        document.getElementById(`totalbulk-total`).scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "center",
        });
        return;
      }

      if(!bulkTotal && bulkTotal != this.inquiryQty){
        this.qtyNotMatchingError = true;
        return;
      }

      if (this.model.totalQuantity > this.inquiryQty) {
        this.exceededDialog = true;
        return;
      }

      if (this.model.totalQuantity < this.inquiryQty) {
        this.subceededDialog = true;
        return;
      }

      this.checkSampleBulkList();
      if(this.sampleQtySaveError) {
        return;
      }

      this.checkBulkList();
      if (this.saveError) {
        return;
      }
      this.$v.model.$touch();
      if (this.$v.model.$invalid) {
        return;
      }
      if(!this.isSubmit && this.$store.state.routeLeaveAlert){
        this.addComment();
        return;
      }
      this.isSubmit = false;
      const payload = {
        target_sales_price: parseFloat(this.model.targetSalesPrice),
        target_make_cost: parseFloat(this.model.targetMakeCost),
        total_quantity: bulkTotal,
        sample_quantity: sampleTotal,
        bulk_breakdowns: JSON.stringify(this.model.bulkColorSizeBreakdown),
        sample_breakdowns: JSON.stringify(this.model.sampleColorSizeBreakdown),
        size_category: this.model.selectedSizeCategory,
        geographical_size_category: this.model.sizeChart,
        kids_geographical_sub_category: this.model.kidsCategory,
        other_size_category: this.model.otherCategory,
        category: this.model.category,
        phone: this.model.numberCode + " " + this.model.number
      };
      return payload;
    },
    async submitAndLeave(){
      const formData = this.verifySubmission();
      if(!formData) return;
      const response = await restAdapter
        .post(
          `/api/inquiries/${this.inquiryId}/fabric_form/first_page`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              this.progress = Math.floor(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          }
        )
      resetLeaveAlert();
      this.ignoreFormEdit = true;
      this.$v.model.$reset();
      this.redirectToCostSheet = false;
      this.$router.push({ name: "Style Costing Sheet" });
    },
    save() {
      this.redirectToCostSheet = false;
      const formData = this.verifySubmission();
      if(!formData) return;
      restAdapter
        .post(
          `/api/inquiries/${this.inquiryId}/fabric_form/first_page`,
          formData
        )
        .then((response) => {
          if (response.data.status){
            this.updateMessage = "Details Updated Successfully";
          } else {
            this.updateMessage = "Details Saved Successfully";
          }

          this.reset();
          notification.success(this.updateMessage);
        })
        .catch((error) => {
          this.loading = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong");
          }
        });
      resetLeaveAlert();
      this.ignoreFormEdit = true;
      this.$v.model.$reset();
      this.continueNextPage();
    },
    PhoneInputNumbersOnly(evt) {
      /*eslint no-var: "error"*/
      /* eslint no-var: off */
      /* eslint-disable */
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
        ;
      } else {
        return true;
      }
    },

  },

  computed: {
    isInvalidPrice() {
      return parseFloat(this.model.targetMakeCost) &&
      parseFloat(this.model.targetSalesPrice) &&
      parseFloat(this.model.targetMakeCost) >= parseFloat(this.model.targetSalesPrice);
    },
    showBreakdowns() {
      if(this.getTheSizeChart && this.getTheSizeChart.sizes && this.getTheSizeChart.sizes.length) return true;
      return false;
    },
    getTheSizeChart() {
      if (this.model.selectedSizeCategory === 1) {
        return this.womensSizeChart[this.model.sizeChart - 1]
      } else if (this.model.selectedSizeCategory === 2) {
        return this.mensSizeChart[this.model.sizeChart - 1]
      } else if (this.model.selectedSizeCategory === 3) {
        if (this.model.kidsCategory === 1) {
          return this.kidsUkSizeCategory[this.model.sizeChart - 1]
        } else if (this.model.kidsCategory === 2) {
          return this.kidsUsSizeCategory[this.model.sizeChart - 1]
        }
      } else if (this.model.selectedSizeCategory === 4 && this.model.otherCategory && this.model.otherSizes.length && this.model.otherSizes[0].size) {
        return {
          sizes: [...this.model.otherSizes.map(item => item.size)]
        }
      }
      return {};
    },
    targetMakeCostErrors() {
      const errors = [];
      if (!this.$v.model.targetMakeCost.$dirty) return errors;
      !this.$v.model.targetMakeCost.required &&
      errors.push("Target Make Cost is required");
      return errors;
    },
    targetSalesPriceErrors() {
      const errors = [];
      if (!this.$v.model.targetSalesPrice.$dirty) return errors;
      !this.$v.model.targetSalesPrice.required &&
      errors.push("Target Make Cost is required");
      return errors;
    },
    totalQuantityErrors() {
      const errors = [];
      if (!this.$v.model.totalQuantity.$dirty) return errors;
      !this.$v.model.totalQuantity.required &&
      errors.push("Total quantity is required");
      !this.$v.model.totalQuantity.validateQuantity &&
      errors.push("The quantity should be between 50 and 100,000,000");
      return errors;
    },
    sizeCategoryErrors() {
      const errors = [];
      if (!this.$v.model.selectedSizeCategory.$dirty) return errors;
      !this.$v.model.selectedSizeCategory.required &&
      errors.push("Size category is required");
      return errors;
    },
    otherCategoryErrors() {
      const errors = [];
      if (!this.$v.model.otherCategory.$dirty) return errors;
      !this.$v.model.otherCategory.required &&
      errors.push("Category is required");
      return errors;
    },
    sizeChartErrors() {
      const errors = [];
      if (!this.$v.model.sizeChart.$dirty) return errors;
      !this.$v.model.sizeChart.required &&
      errors.push("Geographical size chart is required");
      return errors;
    },
    kidsCategoryErrors() {
      const errors = [];
      if (!this.$v.model.kidsCategory.$dirty) return errors;
      !this.$v.model.kidsCategory.required &&
      errors.push("Kids geographical category is required");
      return errors;
    },
    countryValidationError() {
      if (!this.$v.model.numberCode.required) {
        return "Country is required"
      } else {
        return null;
      }
    },
    numberValidationError() {
      if (!this.$v.model.number.required) {
        return "Phone Number is required";
      }
      if (!this.$v.model.number.minLength) {
        return "Phone Number should contain between 7-16 characters";
      }
      if (!this.$v.model.number.maxLength) {
        return "Phone Number should be less than 16 characters";
      } else {
        return null;
      }
    },
    categoryValidationError() {
      if (!this.$v.model.category.required) {
        return "The category is required";
      }
      return null;
    },
    sampleQunatityErrors() {
      const errors = [];
      if (!this.$v.model.sampleQunatity.$dirty) return errors;
      !this.$v.model.sampleQunatity.required &&
      errors.push("Sample Quantity is required");
      return errors;
    },
  },

  async mounted() {
    this.baseUrl = conf.default.server.host;
    this.inquiryId = this.$route.params.id;

    this.loading = true;

    this.sampleSelectList();

    await this.getCategories().then((response) => {
      this.categories = response.data.categories;
      this.ignoreFormEdit = true;
      resetLeaveAlert();
    });

    await this.getInquiry(this.inquiryId).then((response) => {
      const inquiry = response.data.inquiry;
      this.model.styleNo = inquiry.style_number;
      this.model.styleName = inquiry.style_name;
      this.model.brandName = inquiry.customer.brand_name;
      this.model.customerFirstName = inquiry.customer.first_name;
      this.model.customerLastName = inquiry.customer.last_name;
      this.model.email = inquiry.customer.email;
      //this.model.phone = inquiry.customer.phone_number;
      //this.model.numberCode = inquiry.customer.phone_number.split(' ')[0];
      //this.model.number = inquiry.customer.phone_number.split(' ')[1];
      this.inquiryQty = inquiry.bulk_order_quantity;
      this.model.totalQuantity = inquiry.bulk_order_quantity;
      this.getCategoryName(parseInt(inquiry.categories));

      if (inquiry.customer.phone_number === null) {
        this.editPhone = true;
        this.model.phone = "";
      } else {
        this.model.phone = inquiry.customer.phone_number;
        this.model.numberCode = inquiry.customer.phone_number.split(' ')[0];
        this.model.number = inquiry.customer.phone_number.split(' ')[1];
      }

      this.ignoreFormEdit = true;

      const countryCodes = codes.customList('countryCode', '+{countryCallingCode}');
      const obj = Object.entries(countryCodes);

      const selectedCountry = obj.find(country => country[1] == this.model.numberCode)
      this.country = (selectedCountry && selectedCountry[0]) || null;

    });

    await this.getProduction()
      .then((response) => {
        const production = response.data.InquiryProduction;
        this.model.selectedSizeCategory = parseInt(production.size_category);
        this.model.sizeChart = parseInt(production.geographical_size_category);
        this.model.kidsCategory = parseInt(
          production.kids_geographical_sub_category
        );

        this.model.otherCategory = production.other_size_category;
        if (!response.data.bulkBreakdowns[0] || !response.data.bulkBreakdowns[0].sizeAndQuantity.length) {
          production.other_size_category = null;
          this.model.totalQuantity = null;
          this.model.selectedSizeCategory = 1;
        }

        this.model.targetSalesPrice = production.target_sales_price;
        this.model.targetMakeCost = production.target_make_cost;
        this.model.sampleQunatity = production.sample_quantity;
        this.sampleColorSizeBreakdown = response.data.sampleBreakdowns;

        for (
          let index = 0;
          index < response.data.sampleBreakdowns.length;
          index++
        ) {
          this.model.sampleColorSizeBreakdown.push(
            response.data.sampleBreakdowns[index]
          );
        }
        this.oldSampleBreakdown = JSON.parse(JSON.stringify(this.model.sampleColorSizeBreakdown));
        this.model.otherSizes.pop();
          for (
            let index = 0;
            index < response.data.bulkBreakdowns[0].sizeAndQuantity.length;
            index++
          ) {
            this.model.otherSizes.push({
              size: response.data.bulkBreakdowns[0].sizeAndQuantity[index].size,
            });
          }
        this.model.otherCategory = production.other_size_category;
        for (
          let index = 0;
          index < response.data.bulkBreakdowns.length;
          index++
        ) {
          this.model.bulkColorSizeBreakdown.push(
            response.data.bulkBreakdowns[index]
          );

          this.loading = false;
          this.ignoreFormEdit = true;
          this.$watch(
            "model.selectedSizeCategory",
            this.selectedSizeCategoryWatcher
          );
          this.$watch("model.sizeChart", this.sizeChartWatcher);
          this.$watch("model.kidsCategory", this.kidsCategoryWatcher);
        }
      })
      .catch((error) => {
        this.loading = false;
        if (
          error.response &&
          error.response.status == 400 &&
          error.response.data &&
          error.response.data.error
        ) {
          notification.errors(error.response.data.error);
        } else {
          this.$watch(
            "model.selectedSizeCategory",
            this.selectedSizeCategoryWatcher
          );
          this.$watch("model.sizeChart", this.sizeChartWatcher);
          this.$watch("model.kidsCategory", this.kidsCategoryWatcher);
        }
      });

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #37474f;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  width: 120px;
  top: 80%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.btn-align {
  display: flex;
  justify-content: flex-end;
}

.section {
  padding: 20px 50px 50px 50px;
}

@media only screen and (max-width: 600px) {
  .btn-align {
    display: flex;
    justify-content: center;
  }

  .section {
    padding: 20px 10px 10px 10px;
  }
}

.md-table-cell {
  width: 100px !important;
  border-top: none !important;
}

.md-content {
  border: 1px solid #582963 !important;
  padding: 20px 20px 0px 20px !important;
  border-radius: 5px !important;
}

.tbody .md-table-row td {
  border-top: none !important;
}

.md-table-cell >>> .md-table-cell-container {
  padding: 3px 0px 0px 8px !important;
}

.md-table.md-theme-default
.md-table-row:hover:not(.md-header-row)
.md-table-cell {
  background: none !important;
}

.required-field {
  color: red;
  font-size: 20px;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.continue_button_color {
  background-color: #492a67 !important;
  border: #492a67 !important;
}
.list_color.md-selected::v-deep .md-list-item-text{
    color: #fa735f !important;
}
.error_msg_color{
  color : #ff5252 !important;
}
.vue-country-select::v-deep .dropdown-list{
  z-index: 2 !important;
}
.invalid-input {
  color: red;
}

</style>
