<template>
  <v-container fluid>
    <div class="tech-pack" style="padding: 10px;">
      <md-steppers class="margins stepper_number_color" :md-active-step.sync="active" md-linear @md-changed="scrollToTop">
        <md-step
          id="first"
          :md-error="firstStepError"
          :md-done.sync="first"
          md-label="Style Information"
        >
          <InquiryAndProductionDetails
            v-on:set-done="setDone('first', 'second')"
            v-on:error="
              setError('first');
              setErrorStep1();
            "
            :isOwner="isOwner()"
            :isEditAccess="isEditAccess()"
            v-if="renderInquiryAndProduction"
          />
        </md-step>

        <md-step
          id="second"
          :md-error="secondStepError"
          :md-done.sync="second"
          md-label="Material Information"
        >
          <FabricSourcingDetails
            v-on:set-done="setDone('second', 'third')"
            v-on:error="
              setError('second');
              setErrorStep2();
            "
            v-if="renderFabricSourcingDetailsComponent"
            @reset-status="forceFabricDetailsRender"
            :isOwner="isOwner()"
            :isEditAccess="isEditAccess()"
          />
        </md-step>
      </md-steppers>
    </div>
    <!-- </base-material-card> -->
  </v-container>
</template>

<script>
import * as conf from "../../conf.yml";
import restAdapter from "../../restAdapter";
import InquiryAndProductionDetails from "./fabricSourcingSteps/InquiryAndProductionDetails.vue";
import FabricSourcingDetails from "./fabricSourcingSteps/FabricSourcingDetails.vue";
import auth from "../../auth";
import { confirmBox, resetLeaveAlert } from "../../utils/confirmBox";

export default {
  name: "FabricSourcingForm",
  components: {
    InquiryAndProductionDetails: InquiryAndProductionDetails,
    FabricSourcingDetails: FabricSourcingDetails,
  },

  data() {
    return {
      baseUrl: "",
      inquiryId: null,
      active: "first",
      first: false,
      second: false,
      third: false,
      firstStepError: null,
      secondStepError: null,
      renderInquiryAndProduction: true,
      renderFabricSourcingDetailsComponent: true,
      createdBy: null,
      assignedAccountManagers: [],
    };
  },

  async mounted() {
    this.baseUrl = conf.default.server.host;
    this.inquiryId = this.$route.params.id;

    this.getInquiry(this.inquiryId).then((response) => {
      const inquiry = response.data.inquiry;
      this.createdBy = inquiry.created_by;
      this.assignedAccountManagers = inquiry.account_managers;

      if (!this.isEditAccess()) {
        this.first = true;
        this.second = true;
        this.third = true;
      }
    });
  },

  methods: {
    scrollToTop(){
      window.scrollTo(0,0);
    },
    getInquiry(id) {
      return restAdapter.get("/api/inquiries/" + id);
    },
    isOwner() {
      return this.createdBy === auth.user.id;
    },
    isEditAccess() {
      return this.createdBy === auth.user.id || (
        this.assignedAccountManagers &&
        this.assignedAccountManagers.find((accountManager) => accountManager.id === auth.user.id)
      )
    },
    setDone(id, index) {
      resetLeaveAlert();
      this[id] = true;

      this.secondStepError = null;
      this.firstStepError = null;

      if (index) {
        this.active = index;
      }
    },
    setError(step) {
      if (step === "first") {
        this.firstStepError = "Fill Production Details!";
      } else if (step === "second") {
        this.secondStepError = "Fill Required Fabric Details";
      }
    },
    setErrorStep1() {
      return true;
    },
    setErrorStep2() {
      return true;
    },
    forceFabricDetailsRender(emitStatus) {
      this.renderFabricSourcingDetailsComponent = false;
      resetLeaveAlert();
      this.$nextTick(() => {

        this.renderFabricSourcingDetailsComponent = true;
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    confirmBox((this.isEditAccess() && this.$store.state.routeLeaveAlert), next);
  }
};
</script>

<style lang="css" scoped>
.contain {
  border: 1px solid gray;
  height: 200px;
  overflow: hidden;
  resize: both;
  width: 320px;
  border-radius: 5px;
  cursor: pointer;
}

.tech-pack .md-steppers >>> .md-steppers-navigation {
  display: block !important;
  /* box-shadow: none !important;
  border-bottom: 1px solid #0d47a1 !important; */
}

.margins {
  margin-left: 100px;
  margin-right: 100px;
}

@media only screen and (max-width: 600px) {
  .tech-pack .md-steppers >>> .md-stepper-text {
    display: none;
  }

  .margins {
    margin-left: 0;
    margin-right: 0;
  }
}

.stepper_number_color::v-deep .md-stepper-header.md-active .md-stepper-number {
    background-color: #c6a8ec !important ;
}

.stepper_number_color::v-deep .md-stepper-header.md-done .md-stepper-number {
    background-color: #c6a8ec !important ;
}

</style>
