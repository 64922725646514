<template>
  <div v-if="loading" class="text-center" style="height: 500px;">
    <md-progress-spinner
      class="color_stroke"
      md-mode="indeterminate"
    ></md-progress-spinner>
  </div>
  <div v-else style="margin-top: 10px 30px 30px 30px;">
    <div v-if="submitting" class="text-center">
      <md-progress-spinner
        class="color_stroke"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div class="grey lighten-5 section">
      <div id="fabric-source-progress-indicator">
        <div v-if="showProgress" class="file-uploading-indicator text-center">
          <label>Uploading {{ progress }}%</label>
          <md-progress-bar
            class="color_stroke"
            md-mode="determinate"
            :md-value="progress"
          ></md-progress-bar>
        </div>
      </div>
      <v-form :readonly="!isEditAccess" class="mt-6" @submit.prevent="save">
        <v-select
          :items="items"
          label="Number of Fabrics"
          color="purple darken-3"
          v-model="model.noOfFabrics"
          outlined
          hint="Please indicate if you are using more than one main fabric types in your garment"
          dense
        ></v-select>
        <div class="text-left">
          <span>Material</span>
          <span class="required-field">*</span>
        </div>
        <v-radio-group
          :error-messages="fabricMaterialErrors"
          @input="$v.model.material.$touch()"
          @blur="$v.model.material.$touch()"
          v-model="model.material"
        >
          <v-radio
            v-for="material in materials"
            :key="material.id"
            :label="material.name"
            :value="material.id"
          ></v-radio>
        </v-radio-group>
        <div class="text-left">
          <span>Style Type</span>
          <span class="required-field">*</span>
        </div>
        <v-radio-group
          :error-messages="fabricStyleErrors"
          @input="$v.model.styleType.$touch()"
          @blur="$v.model.styleType.$touch()"
          v-model="model.styleType"
        >
          <v-radio
            v-for="(style, index) in styles"
            :key="index"
            :label="style"
            :value="style"
          ></v-radio>
        </v-radio-group>
        <div class="text-left">
          <span>Sourcing Mechanism</span>
          <span class="required-field">*</span>
        </div>
        <v-radio-group
          :error-messages="sourcingMechanismErrors"
          @input="$v.model.sourcingMechanism.$touch()"
          @blur="$v.model.sourcingMechanism.$touch()"
          v-model="model.sourcingMechanism"
        >
          <v-radio
            v-for="(sourcing, index) in sourcingMechanism"
            :key="index"
            :label="sourcing"
            :value="sourcing"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          v-model="model.fabricComposition"
          outlined
          dense
          hint="Please update the fabric composition you hope to use. If you are not sure of it, please mentioned as 'N/A'"
          :error-messages="fabricCompositionErrors"
          @input="$v.model.fabricComposition.$touch()"
          @blur="$v.model.fabricComposition.$touch()"
          color="purple darken-3"
        >
          <template slot="label">
            <span>Fabric Composition</span>
            <span class="required-field">*</span>
          </template>
        </v-text-field>
        <v-text-field
          label="Fabric Weight (GSM)"
          v-model="model.fabricWeight"
          outlined
          dense
          hint="If you have an intended fabric weight (GSM), please add it here."
          color="purple darken-3"
        ></v-text-field>
        <v-text-field
          label="Fabric Width"
          v-model="model.fabricWidth"
          outlined
          dense
          hint="If you know the fabric width, please add that here"
          color="purple darken-3"
        ></v-text-field>
        <v-textarea
          class="mt-4 mb-5"
          outlined
          name="input-7-4"
          clearable
          clear-icon="mdi-close-circle"
          :error-messages="fabricFeaturesErrors"
          @input="$v.model.fabricFeatures.$touch()"
          @blur="$v.model.fabricFeatures.$touch()"
          color="purple darken-3"
          hint="Please add information about the fabric including features as per priority order. (You can use feature like 1. soft hand feel, 2. moisture absorbency, etc.). You can add links to the similar fabrics or products too"
          v-model="model.fabricFeatures"
        >
          <template slot="label">
            <span> Fabric Features and Constructions</span>
            <span class="required-field">*</span>
          </template>
        </v-textarea>
        <div class="mb-3">Fabric Pictures</div>
        <div v-if="totalFabricFileSizeError" style="color:red">
          {{ fileSummationErrorMessage }}
        </div>
        <div v-if="fabricFileSizeError" style="color:red">
          {{ maxFileSizeErrorMessage }}
        </div>
        <vue-dropzone
          v-if="isEditAccess"
          class="dropzone mb-5"
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="addFabricFiles"
          @vdropzone-removed-file="removeFabricFiles"
          @vdropzone-error="collectErrors"
        ></vue-dropzone>
        <small>
          Please Upload any Relevant Swatch Images. High Quality Images Will Be
          Best for Us Get A Proper Understand of the Fabric Type You Like.
        </small>

        <div
          v-if="!isEditAccess && !model.fabricFiles.length"
          style="color: red"
        >
          * No any file has been uploaded !
        </div>

        <v-row class="mb-10" dense>
          <v-col
            v-for="(file, index) in model.fabricFiles"
            :key="index"
            md="6"
            lg="3"
            sm="3"
            xs="6"
          >
            <v-card>
              <v-img
                v-if="
                  (file.file.name || file.file.upload_name).split('.')[1] !==
                    'jpg' &&
                    (file.file.name || file.file.upload_name).split('.')[1] !==
                      'JPG' &&
                    (file.file.name || file.file.upload_name).split('.')[1] !==
                      'png' &&
                    (file.file.name || file.file.upload_name).split('.')[1] !==
                      'jpeg'
                "
                :src="require('../../../assets/folder.png')"
                class="white--text align-end"
                height="150px"
              >
              </v-img>
              <v-img
                v-else
                :src="
                  `${baseUrl}/api/tech_pack/file_show/${file.file.name ||
                    file.file.upload_name}`
                "
                class="white--text align-end"
                height="150px"
              >
              </v-img>

              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div
                    v-text="
                      (file.file.name || file.file.upload_name).substr(0, 20)
                    "
                  ></div>
                </v-row>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_show/${file.file.name ||
                      file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_download/${file.file.name ||
                      file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn
                  v-if="isEditAccess"
                  fab
                  x-small
                  @click="
                    deleteFile(
                      file.file.name || file.file.upload_name,
                      'fabric'
                    )
                  "
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <div class="mb-3">Any Other Related Information</div>
        <div v-if="otherTotalFabricFileSizeError" style="color:red">
          {{ fileSummationErrorMessage }}
        </div>
        <div v-if="otherFabricFileSizeError" style="color:red">
          {{ maxFileSizeErrorMessage }}
        </div>
        <vue-dropzone
          v-if="isEditAccess"
          class="dropzone mb-5"
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="addOtherFabricFiles"
          @vdropzone-removed-file="removeOtherFabricFiles"
          @vdropzone-error="collectErrors"
        ></vue-dropzone>
        <small
          >If There are Any Other Information to Be Shared Please Upload Them
          Here.</small
        >

        <div
          v-if="!isEditAccess && !model.otherFabricFiles.length"
          style="color: red"
        >
          * No any file has been uploaded !
        </div>

        <v-row class="mb-10" dense>
          <v-col
            v-for="(file, index) in model.otherFabricFiles"
            :key="index"
            md="6"
            lg="3"
            sm="3"
            xs="6"
          >
            <v-card>
              <v-img
                v-if="
                  (file.file.name || file.file.upload_name).split('.')[1] !==
                    'jpg' &&
                    (file.file.name || file.file.upload_name).split('.')[1] !==
                      'JPG' &&
                    (file.file.name || file.file.upload_name).split('.')[1] !==
                      'png' &&
                    (file.file.name || file.file.upload_name).split('.')[1] !==
                      'jpeg'
                "
                :src="require('../../../assets/folder.png')"
                class="white--text align-end"
                height="150px"
              >
              </v-img>
              <v-img
                v-else
                :src="
                  `${baseUrl}/api/tech_pack/file_show/${file.file.name ||
                    file.file.upload_name}`
                "
                class="white--text align-end"
                height="150px"
              >
              </v-img>

              <v-card-text>
                <v-row align="center" class="mx-0">
                  <div
                    v-text="
                      (file.file.name || file.file.upload_name).substr(0, 20)
                    "
                  ></div>
                </v-row>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_show/${file.file.name ||
                      file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  fab
                  x-small
                  :href="
                    `${baseUrl}/api/tech_pack/file_download/${file.file.name ||
                      file.file.upload_name}`
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn
                  v-if="isEditAccess"
                  fab
                  x-small
                  @click="
                    deleteFile(
                      file.file.name || file.file.upload_name,
                      'other_fabric'
                    )
                  "
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <div class="text-left">
          Do You Want Fabric Swatches to Be Sent to You?
        </div>
        <v-radio-group v-model="model.fabricSwatch">
          <v-radio label="Yes" :value="1"></v-radio>
          <v-radio label="No" :value="0"></v-radio>
        </v-radio-group>

        <v-row v-if="model.fabricSwatch">
          <v-col cols="12" md="6">
            <label>Delivery Address</label>
            <div>
              <v-text-field
                ref="address"
                v-model="model.deliveryAddressLine1"
                label="Address Line 1"
                :required="true"
                :error-messages="addressLine1ValidationError"
              ></v-text-field>
              <v-text-field
                ref="address"
                v-model="model.deliveryAddressLine2"
                label="Address Line 2"
              ></v-text-field>
              <v-text-field
                ref="city"
                v-model="model.deliveryAddressCity"
                label="City"
                :error-messages="addressCityValidationError"
              ></v-text-field>
              <v-text-field
                ref="state"
                v-model="model.deliveryAddressState"
                label="State/Province/Region"
              ></v-text-field>
              <v-text-field
                ref="zip"
                v-model="model.deliveryAddressPostal"
                label="ZIP / Postal Code"
                :error-messages="addressPostalValidationError"
              ></v-text-field>
              <v-autocomplete
                ref="country"
                v-model="model.deliveryAddressCountry"
                :items="countries"
                label="Country"
                placeholder="Select..."
                :error-messages="addressCountryValidationError"
              ></v-autocomplete>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <label>Phone Number</label>
            <v-row>
              <v-col cols="2" md="2">
                <vue-country-code
                  :disabled="!isEditAccess"
                  style="height: 40px;"
                  @onSelect="onSelect"
                  :defaultCountry="country"
                >
                </vue-country-code>
              </v-col>
              <v-col cols="10" md="10">
                <v-text-field
                  v-model="model.number"
                  v-on:keypress="PhoneInputNumbersOnly"
                  :prefix="model.numberCode"
                  :error-messages="numberValidationError"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div
          class="grey lighten-5 mt-10 section"
          style="text-align: left"
          v-if="isOwner && isSubmitted === 0"
        >
          <h2 class="mb-2">Terms and Conditions</h2>
          <ul>
            <li>
              I ensure above details are correct according to my knowledge and I
              have review all information before submit.
            </li>
            <li>
              I am taking the full responsibility if the fabric were not matched
              to my request due to information inaccuracy.
            </li>
          </ul>
          <v-checkbox
            v-model="model.termsAndCondition"
            :error-messages="checkboxErrors"
            label="I accept the Terms and Conditions."
            required
            @change="$v.model.termsAndCondition.$touch()"
            @blur="$v.model.termsAndCondition.$touch()"
          ></v-checkbox>
        </div>
        <div style="display: flex; justify-content: center;">
          <v-alert
            class="mt-5"
            type="error"
            max-width="300px"
            dense
            :value="alert"
            transition="scale-transition"
          >
            Remove unsupported files
          </v-alert>
        </div>
        <div class="btn-align">
          <v-btn color="#ff5252" v-if="isEditAccess" @click="save">
            Save
          </v-btn>

          <v-btn
            v-if="isOwner && isSubmitted === 0"
            class="continue_button_color"
            @click="submit"
            >Submit
          </v-btn>
        </div>
      </v-form>
    </div>
    <v-dialog max-width="500" v-model="commentDialog">
      <Comment
        @comment-submit="commentSubmit"
        @comment-close="commentDialog = false"
        :page="2"
        serviceType="fabric"
      />
    </v-dialog>
  </div>
</template>

<script>
import * as conf from "../../../conf.yml";
import { validationMixin } from "vuelidate";
import {
  required,
  requiredIf,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import restAdapter from "../../../restAdapter";
import notification from "../../../notification";
import countryList from "country-list";
import Vue from "vue";
import auth from "../../../auth";
import Comment from "../Comment.vue";
import countryCodes from "country-codes-list";
import { setLeaveAlert } from "../../../utils/confirmBox";
import routes from '../../../router/routes';

Vue.use(VueViewer);

import VueCountryCode from "vue-country-code";
import codes from "country-codes-list";

Vue.use(VueCountryCode);

export default {
  name: "FabricSourcingDetails",
  mixins: [validationMixin],
  components: {
    vueDropzone: vue2Dropzone,
    Comment,
  },

  data() {
    return {
      submitFabric: false,
      country: "",
      baseUrl: "",
      inquiryId: "",
      materials: [],
      items: [1, 2, 3, 4, 5],
      termsAndCondition: false,
      alert: false,
      isSubmitted: 0,
      saveAndSubmit: false,
      submitting: false,
      countries: [],
      countryCodes: [],
      arr: [],
      customerId: null,
      updateMessage: "",
      styles: [
        "Repetitive Style (Plan to Make in Future with Same Fabric)",
        "One Time Style (Make the Style Only One Time)",
      ],
      sourcingMechanism: [
        "Source From Available Materials (These Materials May Not Available at A Second Purchase. But It Will Be Good If You Purchase Lower Quantity)",
        "Source Specific Materials (eg - Organic, High Tech, Echo, Etc)",
      ],
      unsupportedFiles: [],
      loading: false,
      fabricFileSizeError: false,
      totalFabricFileSizeError: false,
      otherFabricFileSizeError: false,
      otherTotalFabricFileSizeError: false,
      totalFileSize: 102000000,
      maximumFileSize: 52000000,
      fileSummationErrorMessage:
        "Selected files are exceeding the maximum file summation limit (100mb)",
      maxFileSizeErrorMessage:
        "One or more files are too large to upload (50mb)",
      fileError: false,
      commentDialog: false,
      isSubmit: false,
      isContinue: false,

      model: {
        noOfFabrics: 1,
        material: "",
        styleType: "",
        sourcingMechanism: "",
        fabricComposition: "",
        fabricWeight: "",
        fabricWidth: "",
        fabricConstruction: "",
        fabricFeatures: "",
        fabricSwatch: 0,
        fabricFiles: [],
        otherFabricFiles: [],
        deliveryAddressLine1: "",
        deliveryAddressLine2: "",
        deliveryAddressCity: "",
        deliveryAddressState: "",
        deliveryAddressPostal: "",
        deliveryAddressCountry: "",
        number: null,
        numberCode: null,
        termsAndCondition: false,
      },
      dropzoneOptions: {
        url: "https://",
        autoProcessQueue: false,
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,
        maxFilesize: 50,
        dictDefaultMessage:
          "<div style='filter: grayscale(1)'>📤</div> Drag and Drop (or) Choose File(s)",

        acceptedFiles:
          ".pdf,.jpg,.jpeg,.png,.svg,.gif,.ai,.eps,.ait,.svgz,.bmp,.css,.dwg,.dxf,.emf,.swf,.pct,.psd,.tif,.tga,.txt,.wmf,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xml,.xlam,.xlw,.xlr,.xla,.mp4,.mp3,.tuka,.gerber,.tiff,.ng,.pxr,.dcm,.pbm,.jps,.dng,.raw,.ct,.tga,.ait,.epsf,.ps,.doc,.docm,.docx,.dot,.dotx,.pptx,.pptm,.ppt,.potx,.potm,.pot,.ppsx,.pps,.ppsm,.cdr,.wav,.mov,.dmg,.aae,.heic,.dotm,.dotx,.dot,.htm,.html,.mht,.mhtml,.odt,.rtf,.wps,.dic,.csv,.dbf,.dif,.ods,.prn,.slk,.xps,.ppa,.ppam,.thmx,.wmv,.abr,.csh,.psb,.u3d,.8ba,.8bam,.8bbr,.8bc,.8bco,.8bct,.8bdt,.8be,.8bem,.8bf,.8bfk,.8bfm,.8bi,.8bi8,.8bim,.8bl,.8bp,.8bpf,.8bpi,.8bs,.8bx,.8bxm,.8bym,.8li,.8ly,.acb,.acf,.acm,.aco,.act,.acv,.ado,.ahs,.ahu,.ai3,.ai8,.alv,.amp,.ams,.asl,.asp,.asr,.ast,.asv,.atf,.atn,.ava,.avs,.axt,.blw,.cha,.cpsd,.csf,.dcm,.dcs2,.dic,.drx,.eap,.edb,.enf,.eps2,.eps3,.ensi,.ensp,.erf,.ffo,.fl3,.gbt,.grd,.hdr,.hdt,.hss,.iros,.irs,.isa,.inf,.imbs,.mag,.mdo,.mos,.mpo,.mtl,.p3e,.p3l,.p3m,.p3r,.pam,.pat,.pdd,.pf,.pipl,.pipeg,.pmb,.pmg,.prst,.psdt,.psf,.pxr,.g2g,.g5r,.rfr,.shc,.ssh,.spiff,.sta,.swb,.tbo,.thr,.tpl,.ype,._v,.3ds,.arw,.cr2,.crw,.csh,.dcr,.dib,.emz,.ico,.kmz,.mrw,.nef,.obj,,orf,,pcd,,plugin,.psdx,.raf,.syg,.wdp,.webp,.acbl,.afx,.ai4,.ai5,.ai6,.ai7,.ase,.asef,.bm2,.cal,.clp,.colz,.dae,.dc2,.dcp,.dcpr,.dcs,.dicm,.dxt5,.exif,.exr,.fff,.fif,.gif~c200,.hdr,.icb,.icc,.icm,.ifi,.iif,.jp2,.jp2,.jpg-large,.jpg-original,.ipx,.ixr,.kdc,.kdk,.kodak,.kys,.lcpp,.lex,.mnu,.mxp,.nrw,.pat,.pbm,.pcq,.pdp,.pef,.png-large,.pnm,.pop,.psid,.q7q,.rwl,.sct,.sdpx,.spf,.srw,.stn=,.tdi,.vph,.wbm,.x3f,.zdct,.aac,.aiff,.m2a,.m4a,.mp2,.h264,.3gp,.3gpp,.avc,.mpeg-2,.mpeg-4,.mts,.mxt,.r3d,.ts,.vob,.dpx,.mov,.jpeg2000,.mpo,.kmz,.jps,.dae,.pcx,.taaga,.svq,.svqz,.aia,.aip,.ait,.aia,.aip,.ait,.cef,.cqm,.clx,.epf,.epsp,.fit,.fmod,.iros,.irs,.kys,.psid,.pwl,.sbx,.pic,.kys,.psid,.pwl,.sbx,.art,.env,.fh10,.fh11,.fh7,.fh8,.fh9,.fh8,.fxq,.idea,.j2k,.jp2,.jpf,.jpx,.lex,.pxr,.vda,.vst,.zdct",

        addRemoveLinks: true,
      },
      ignoreFormEdit: false,
      showProgress: false,
      progress: 0,
    };
  },
  validations: {
    model: {
      material: { required },
      styleType: { required },
      sourcingMechanism: { required },
      fabricComposition: { required },
      fabricFeatures: { required },
      termsAndCondition: {
        checked(val) {
          if (this.saveAndSubmit) {
            return val;
          } else {
            return true;
          }
        },
      },
      numberCode: {
        required: requiredIf((model) => {
          return model.fabricSwatch === 1;
        }),
      },
      number: {
        required: requiredIf((model) => {
          return model.fabricSwatch === 1;
        }),
        minLength: minLength(7),
        maxLength: maxLength(16),
      },
      deliveryAddressLine1: {
        required: requiredIf((model) => {
          return model.fabricSwatch === 1;
        }),
      },
      deliveryAddressCity: {
        required: requiredIf((model) => {
          return model.fabricSwatch === 1;
        }),
      },
      deliveryAddressPostal: {
        required: requiredIf((model) => {
          return model.fabricSwatch === 1;
        }),
      },
      deliveryAddressCountry: {
        required: requiredIf((model) => {
          return model.fabricSwatch === 1;
        }),
      },
    },
  },
  props: {
    isOwner: {
      type: Boolean,
    },
    isEditAccess: {
      type: Boolean,
    },
  },

  watch: {
    model: {
      handler: function() {
        this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
      },
      deep: true,
    },
  },

  methods: {
    onSelect({ name, iso2, dialCode }) {
      this.model.numberCode = "+" + dialCode;
    },
    continueToNext() {
      this.$emit("set-done");
    },

    openFileHandler({ file }) {
      window.open(
        `${conf.server.host}/api/inquiries/file_show/${file.file.upload_name}`
      );
    },

    continueStep() {
      this.$v.model.$touch();

      this.$nextTick(() => {
        if (document.querySelector(".v-messages__message")) {
          const domRect = document
            .querySelector(".v-messages__message")
            .getBoundingClientRect();
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop
          );
        }
      });

      if (this.$v.model.$invalid) {
        this.$emit("error");
        return;
      }
      this.isContinue = true;
      this.save();
    },
    reset() {
      this.$emit("reset-status", false);
    },
    getMaterials() {
      return restAdapter.get("/api/inquire_materials");
    },
    getInquiry(id) {
      return restAdapter.get("/api/inquiries/" + id);
    },
    getFabricData() {
      return restAdapter.get(`/api/inquiries/${this.inquiryId}/fabric_form`);
    },

    addFabricFiles: function(file) {
      if (file.size > this.maximumFileSize) {
        this.fabricFileSizeError = true;
      }

      this.model.fabricFiles.push({ file });

      let fileSize = 0;
      for (let index = 0; index < this.model.fabricFiles.length; index++) {
        fileSize =
          fileSize +
          (this.model.fabricFiles[index].file.size
            ? this.model.fabricFiles[index].file.size
            : 0);
      }

      this.totalFabricFileSizeError =
        fileSize > this.totalFileSize ? true : false;
    },

    removeFabricFiles(file) {
      let fileSize = 0;
      for (let index = 0; index < this.model.fabricFiles.length; index++) {
        if (this.model.fabricFiles[index].file.name === file.name) {
          this.model.fabricFiles.splice(index, 1);
        }
        fileSize =
          fileSize +
          (this.model.fabricFiles[index] &&
          this.model.fabricFiles[index].file &&
          this.model.fabricFiles[index].file.size
            ? this.model.fabricFiles[index].file.size
            : 0);
      }
      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.totalFabricFileSizeError =
        fileSize > this.totalFileSize ? true : false;
      this.fabricFileSizeError =
        this.unsupportedFiles.length > 0 ? true : false;
    },

    addOtherFabricFiles: function(file) {
      if (file.size > this.maximumFileSize) {
        this.otherFabricFileSizeError = true;
      }

      this.model.otherFabricFiles.push({ file });

      let fileSize = 0;
      for (let index = 0; index < this.model.otherFabricFiles.length; index++) {
        fileSize =
          fileSize +
          (this.model.otherFabricFiles[index].file.size
            ? this.model.otherFabricFiles[index].file.size
            : 0);
      }
      this.otherTotalFabricFileSizeError =
        fileSize > this.totalFileSize ? true : false;
    },
    removeOtherFabricFiles(file) {
      let fileSize = 0;
      for (let index = 0; index < this.model.otherFabricFiles.length; index++) {
        if (this.model.otherFabricFiles[index].file.name === file.name) {
          this.model.otherFabricFiles.splice(index, 1);
        }
        fileSize =
          fileSize +
          (this.model.otherFabricFiles[index] &&
          this.model.otherFabricFiles[index].file &&
          this.model.otherFabricFiles[index].file.size
            ? this.model.otherFabricFiles[index].file.size
            : 0);
      }
      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.otherTotalFabricFileSizeError =
        fileSize > this.totalFileSize ? true : false;
      this.otherFabricFileSizeError =
        this.unsupportedFiles.length > 0 ? true : false;
    },
    collectErrors(file, message, xhr) {
      this.unsupportedFiles.push({ file });
    },
    deleteFile(uniqueId, type) {
      restAdapter
        .delete("/api/tech_pack/file_delete/" + uniqueId)
        .then((res) => {
          notification.success("File deleted successfully");

          if (type === "fabric") {
            for (
              let index = 0;
              index < this.model.fabricFiles.length;
              index++
            ) {
              if (this.model.fabricFiles[index].file.upload_name == uniqueId) {
                this.model.fabricFiles.splice(index, 1);
              }
            }
          } else if (type === "other_fabric") {
            for (
              let index = 0;
              index < this.model.otherFabricFiles.length;
              index++
            ) {
              if (
                this.model.otherFabricFiles[index].file.upload_name == uniqueId
              ) {
                this.model.otherFabricFiles.splice(index, 1);
              }
            }
          }
        });
    },
    checkNullValues() {
      for (const key in this.model) {
        if (this.model[key] == null) {
          this.model[key] = "";
        }
      }
    },
    commentSubmit() {
      this.commentDialog = false;
      this.isSubmit = true;
      this.save();
      this.continueNextPage();
    },
    continueNextPage() {
      if (this.isContinue) {
        this.isContinue = false;
        this.$emit("set-done");
      }
    },
    addComment() {
      if (!this.isOwner) {
        this.commentDialog = true;
      } else {
        this.commentSubmit();
      }
    },
    async save() {
      this.saveAndSubmit = false;
      this.$v.model.$touch();
      if (this.$v.model.$invalid) {
        return;
      }

      if (!this.isSubmit && this.$store.state.routeLeaveAlert) {
        this.addComment();
        return;
      }
      this.isSubmit = false;

      if (
        this.fabricFileSizeError ||
        this.totalFabricFileSizeError ||
        this.otherFabricFileSizeError ||
        this.otherTotalFabricFileSizeError
      ) {
        return;
      }

      this.$nextTick(() => {
        if (document.querySelector(".v-messages__message")) {
          const domRect = document
            .querySelector(".v-messages__message")
            .getBoundingClientRect();
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop
          );
        }
      });

      if (this.$v.model.$invalid) {
        return;
      }

      if (this.unsupportedFiles.length > 0) {
        this.alert = true;
        return;
      }

      const payload = new FormData();
      for (let index = 0; index < this.model.fabricFiles.length; index++) {
        payload.append("fabric_files[]", this.model.fabricFiles[index].file);
      }

      for (let index = 0; index < this.model.otherFabricFiles.length; index++) {
        payload.append(
          "other_fabric_files[]",
          this.model.otherFabricFiles[index].file
        );
      }

      payload.append("no_of_fabrics", this.model.noOfFabrics);
      payload.append("material", this.model.material);
      payload.append("style_type", this.model.styleType);
      payload.append("sourcing_mechanism", this.model.sourcingMechanism);
      payload.append("fabric_weight", this.model.fabricWeight);
      payload.append("fabric_width", this.model.fabricWidth);
      payload.append("construction_and_features", this.model.fabricFeatures);
      payload.append("fabric_composition", this.model.fabricComposition);
      payload.append("send_swatches", this.model.fabricSwatch);
      payload.append("size_category", this.model.selectedSizeCategory);
      payload.append("geographical_size_category", this.model.sizeChart);
      payload.append("kids_geographical_sub_category", this.model.kidsCategory);
      payload.append("other_size_category", this.model.otherCategory);
      payload.append("delivery_address_line1", this.model.deliveryAddressLine1);
      payload.append("delivery_address_line2", this.model.deliveryAddressLine2);
      payload.append("delivery_address_city", this.model.deliveryAddressCity);
      payload.append("delivery_address_state", this.model.deliveryAddressState);
      payload.append(
        "delivery_address_postal",
        this.model.deliveryAddressPostal
      );
      payload.append(
        "delivery_address_country",
        this.model.deliveryAddressCountry
      );
      payload.append(
        "phone_number",
        this.model.numberCode + " " + this.model.number
      );
      payload.append(
        "terms_and_condition",
        this.model.termsAndCondition ? 1 : 0
      );

      this.showProgress = true;
      document
        .getElementById("fabric-source-progress-indicator")
        .scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "center",
        });
      try {
        await restAdapter
          .post(
            `/api/inquiries/${this.inquiryId}/fabric_form/second_page`,
            payload,
            {
              onUploadProgress: (progressEvent) => {
                this.progress = Math.floor(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              },
            }
          )
          .then((response) => {
            this.showProgress = false;
            this.submitFabricForm();
            if (response.data.status) {
              this.updateMessage = "Details Updated Successfully";
            } else {
              this.updateMessage = "Details Saved Successfully";
            }
            this.reset();
            this.$v.model.$reset();
            notification.success(this.updateMessage);
          });
      } catch (error) {
        this.showProgress = false;
        this.loading = false;
        if (
          error.response &&
          error.response.status == 400 &&
          error.response.data &&
          error.response.data.error
        ) {
          notification.errors(error.response.data.error);
        } else {
          notification.error("Something went wrong, please try again later.");
        }
      }
      this.loading = false;
    },
    submit() {
      this.submitFabric = true;
      this.saveAndSubmit = true;
      this.$v.model.$touch();
      if (this.$v.model.$invalid) {
        return;
      }

      if (
        this.fabricFileSizeError ||
        this.totalFabricFileSizeError ||
        this.otherFabricFileSizeError ||
        this.otherTotalFabricFileSizeError
      ) {
        return;
      }
      this.submitting = true;
      this.save();
    },

    PhoneInputNumbersOnly(evt) {
      /*eslint no-var: "error"*/
      /* eslint no-var: off */
      /* eslint-disable */
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async submitFabricForm() {
      if (this.submitFabric) {
        this.submitFabric = false;
        this.submitting = true;
        await restAdapter
          .post(`/api/inquiries/${this.inquiryId}/fabric_form/submit`)
          .then(() => {
            this.reset();
            notification.success("Fabric Sourcing Form Submitted Successfully");
          })
          .catch((error) => {
            notification.error("Something went wrong, please try again later.");
          });
        this.saveAndSubmit = false;
        this.submitting = false;
      }
    },
  },

  computed: {
    fabricMaterialErrors() {
      const errors = [];
      if (!this.$v.model.material.$dirty) return errors;
      !this.$v.model.material.required &&
        errors.push("Fabric material is required");
      return errors;
    },
    fabricStyleErrors() {
      const errors = [];
      if (!this.$v.model.styleType.$dirty) return errors;
      !this.$v.model.styleType.required &&
        errors.push("Style type is required");
      return errors;
    },
    sourcingMechanismErrors() {
      const errors = [];
      if (!this.$v.model.sourcingMechanism.$dirty) return errors;
      !this.$v.model.sourcingMechanism.required &&
        errors.push("Sourcing mechanism is required");
      return errors;
    },
    fabricCompositionErrors() {
      const errors = [];
      if (!this.$v.model.fabricComposition.$dirty) return errors;
      !this.$v.model.fabricComposition.required &&
        errors.push("Fabric composition is required");
      return errors;
    },
    fabricFeaturesErrors() {
      const errors = [];
      if (!this.$v.model.fabricFeatures.$dirty) return errors;
      !this.$v.model.fabricFeatures.required &&
        errors.push("Fabric feature is required");
      return errors;
    },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.model.termsAndCondition.$dirty) return errors;
      !this.$v.model.termsAndCondition.checked &&
        this.saveAndSubmit &&
        errors.push("You must accept the terms and conditions.");
      return errors;
    },
    countryValidationError() {
      if (!this.$v.model.numberCode.required) {
        return "Country is required";
      } else {
        return null;
      }
    },
    numberValidationError() {
      if (!this.$v.model.number.required && this.model.fabricSwatch === 1) {
        return "Phone Number is required";
      }
      if (!this.$v.model.number.minLength && this.model.fabricSwatch === 1) {
        return "Phone Number should contain between 7-16 characters";
      }
      if (!this.$v.model.number.maxLength && this.model.fabricSwatch === 1) {
        return "Phone Number should be less than 16 characters";
      } else {
        return null;
      }
    },
    addressCountryValidationError() {
      if (
        !this.$v.model.deliveryAddressCountry.required &&
        this.model.fabricSwatch === 1
      ) {
        return "Country is required";
      } else {
        return null;
      }
    },
    addressLine1ValidationError() {
      if (
        !this.$v.model.deliveryAddressLine1.required &&
        this.model.fabricSwatch === 1
      ) {
        return "Address line 1 is required";
      } else {
        return null;
      }
    },
    addressCityValidationError() {
      if (
        !this.$v.model.deliveryAddressCity.required &&
        this.model.fabricSwatch === 1
      ) {
        return "City is required";
      } else {
        return null;
      }
    },
    addressPostalValidationError() {
      if (
        !this.$v.model.deliveryAddressPostal.required &&
        this.model.fabricSwatch === 1
      ) {
        return "City is required";
      } else {
        return null;
      }
    },
    addressStateValidationError() {
      if (
        !this.$v.model.deliveryAddressState.required &&
        this.model.fabricSwatch === 1
      ) {
        return "City is required";
      } else {
        return null;
      }
    },
  },

  async mounted() {
    this.baseUrl = conf.default.server.host;
    this.inquiryId = this.$route.params.id;
    this.loading = true;
    this.getMaterials().then((response) => {
      this.materials = response.data.materials;
    });

    this.countries = countryList.getNames();
    this.countryCodes = countryCodes.customList(
      "countryNameEn",
      "+{countryCallingCode}"
    );
    const obj = Object.entries(this.countryCodes);
    for (let i = 0; i < obj.length; i++) {
      this.arr.push({ country: obj[i][0], code: obj[i][1] });
    }
    this.arr.sort((a, b) => a.country.localeCompare(b.country));

    await this.getFabricData()
      .then((response) => {
        this.model.material = parseInt(
          response.data.fabricData.material || null
        );
        this.ignoreFormEdit = true;

        this.model.deliveryAddressLine1 = response.data.deliveryData.delivery_address_line1;
        this.model.deliveryAddressLine2 = response.data.deliveryData.delivery_address_line2;
        this.model.deliveryAddressCity = response.data.deliveryData.delivery_address_city;
        this.model.deliveryAddressState = response.data.deliveryData.delivery_address_state;
        this.model.deliveryAddressPostal = response.data.deliveryData.delivery_address_post_code;
        this.model.deliveryAddressCountry = response.data.deliveryData.delivery_address_country;
        this.model.numberCode = response.data.deliveryData.phone_number.split(' ')[0].substring(-1);
        this.model.number = response.data.deliveryData.phone_number.split(' ')[1];

        const countryCodes = codes.customList('countryCode', '+{countryCallingCode}');

        const obj = Object.entries(countryCodes);

        const selectedCountry = obj.find(
          (country) => country[1] == this.model.numberCode
        );
        this.country = (selectedCountry && selectedCountry[0]) || null;
        this.ignoreFormEdit = true;

        if (response.data.fabricData.fabricSourcing !== null) {
          const fabricData = response.data.fabricData.fabricSourcing;
          this.model.noOfFabrics = parseInt(fabricData.no_of_fabrics);
          this.model.styleType = fabricData.style_type;
          this.model.sourcingMechanism = fabricData.sourcing_mechanism;
          this.model.fabricWeight =
            fabricData.fabric_weight == "null"
              ? null
              : fabricData.fabric_weight;
          this.model.fabricWidth =
            fabricData.fabric_width == "null" ? null : fabricData.fabric_width;
          this.model.fabricFeatures = fabricData.construction_and_features;
          this.model.fabricSwatch = fabricData.send_swatches;
          this.model.termsAndCondition =
            fabricData.terms_and_condition === 1 ? true : false;
          this.isSubmitted = fabricData.is_submitted;
        }
        this.model.fabricComposition =
          response.data.fabricData.inquiryProduction.fabric_composition;

        for (
          let index = 0;
          index < response.data.fabricData.fabricFiles.length;
          index++
        ) {
          this.model.fabricFiles.push({
            file: response.data.fabricData.fabricFiles[index],
          });
        }
        for (
          let index = 0;
          index < response.data.fabricData.otherFabricFiles.length;
          index++
        ) {
          this.model.otherFabricFiles.push({
            file: response.data.fabricData.otherFabricFiles[index],
          });
        }
        this.ignoreFormEdit = true;
      })
      .catch((error) => {
        this.loading = false;
        if (
          error.response &&
          error.response.status == 400 &&
          error.response.data &&
          error.response.data.error
        ) {
          notification.errors(error.response.data.error);
        }
        if(error.response.status === 401){
          notification.error("You don't have Access");
          this.$router.push({name: routes.COLLECTION_LIST});
        }
      });
    this.loading = false;
  },
};
</script>

<style lang="css" scoped>
.btn-align {
  display: flex;
  justify-content: flex-end;
}

.dropzone:hover {
  border: dashed #c6a8ec;
}

.dropzone {
  border-radius: 5px;
}

.dropzone >>> .dz-preview .dz-progress {
  opacity: 0 !important;
}

.section {
  padding: 20px 50px 50px 50px;
}

.dropzone >>> .dz-error-message {
  display: none !important;
}

.dropzone >>> .dz-preview .dz-details {
  background-color: rgb(198, 168, 236, 0.7) !important;
}

.dropzone >>> .dz-preview .dz-remove {
  color: #582963 !important;
}

@media only screen and (max-width: 600px) {
  .btn-align {
    display: flex;
    justify-content: center;
  }
  .section {
    padding: 20px 10px 10px 10px;
  }
}

.required-field {
  color: red;
  font-size: 20px;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.continue_button_color {
  background-color: #492a67 !important;
  border: #492a67 !important;
}

.md-progress-bar {
  height: 25px;
  margin: 5px;
}

.md-progress-bar.md-theme-default.md-determinate::v-deep .md-progress-bar-fill {
  background-color: #492a67 !important;
}
.md-progress-bar.md-theme-default.md-determinate {
  background-color: #c6a8ec !important;
}
</style>
